<template>
    <div class="adevelPmentBox">
        <!-- 标题 -->
        <div class="dpTitle">
            <div class="dpBig">发展历程</div>
            <div class="dpText">
                <div v-html="topInfo.intro.replaceAll('\n\n', '<br />')"></div>
            </div>
        </div>
        <!-- 发展历程 -->
        <div class="CourseBox" v-if="list.length">
            <div class="cbBox">
                <!-- 中间分割线 -->
                <!-- <div class="splitLine"></div> -->
                <ul>
                    <li v-for="(el, i) in list" :key="i">
                        <div @mouseenter="isActive = i" :class="{ active: isActive === i }">
                            <!-- 线与小圆 -->
                            <div class="hr">
                                <div></div>
                            </div>
                            <p>{{ el.time }}</p>
                            <p>{{ el.intro }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <img v-else src="https://iv.vu818.com/img/zwsj.png" style="width: 300px" />
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Adevelopment",
    data() {
        return {
            isActive: 0,
            topInfo: {},
            list: [
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
                {
                    time: "2023",
                    info: "2021年，为及时响应客户需求，北京威有在济南市、天津市设立分部、北京市黄村、北京市威有设立业务服务点。",
                },
            ],
        };
    },
    created() {
        axios.get("/api/development_history/history").then(({ data }) => {
            // console.log(data);
            this.topInfo = data.find(e => e.id == 1);
            console.log(this.topInfo);
            this.list = data.filter(e => e.id != 1);
        });
    },
    methods: {},
};
</script>

<style lang="less" scoped>
.adevelPmentBox {
    width: 1280px;
    margin: 0 auto;
    margin-top: 121px;
    margin-bottom: 20px;
    // 标题
    .dpTitle {
        width: 80%;
        height: 200px;
        margin: 0 auto;
        .dpBig {
            width: 100%;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 40px;
        }
        .dpText {
            width: 100%;
            height: 90px;
            p {
                width: 100%;
                height: 30px;
                line-height: 30px;
                text-align: center;
            }
        }
    }
    // 发展历程
    .CourseBox {
        width: 100%;
        margin-top: 50px;
        position: relative;
        .cbBox {
            width: 100%;
            height: 700px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            & > ul {
                padding-top: 55px;
                li {
                    height: 150px;
                    margin-top: -55px;
                    & > div {
                        width: 500px;
                        height: 150px;
                        border-radius: 10px;
                        padding: 20px 25px;
                        position: relative;
                        .hr {
                            position: absolute;
                            width: 62px;
                            height: 1px;
                            background-color: black;
                            top: 50%;
                            & > div {
                                width: 10px;
                                height: 10px;
                                background-color: #7781f1;
                                position: absolute;
                                border-radius: 50%;
                                top: -5px;
                            }
                            &::before {
                                content: " ";
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 1px;
                                height: 150px;
                                background-color: black;
                            }
                        }
                        & > p:nth-of-type(1) {
                            font-size: 30px;
                            font-weight: 900;
                            color: #7781f1;
                        }
                        & > p:nth-of-type(2) {
                            font-size: 15px;
                        }
                    }
                    &:nth-of-type(even) {
                        // 偶
                        div {
                            float: right;
                            margin-right: 72px;
                            .hr {
                                left: -62px;
                                & > div {
                                    left: -10px;
                                }
                                &::before {
                                    left: -6px;
                                }
                            }
                        }
                    }
                    &:nth-of-type(odd) {
                        // 奇
                        div {
                            float: left;
                            margin-left: 72px;

                            .hr {
                                right: -62px;
                                & > div {
                                    right: -10px;
                                }
                                &::before {
                                    right: -7px;
                                }
                            }
                        }
                    }
                    .active {
                        background: linear-gradient(90deg, #7781f1, rgba(119, 129, 241, 0.8784313725490196));
                        p {
                            color: white !important;
                        }
                    }
                }
            }
        }

        // 分割线
        .splitLine {
            width: 1px;
            height: 700px;
            background-color: black;
            position: absolute;
            top: 0px;
            left: 50%;
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }
}
</style>
